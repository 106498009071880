<template>
  <v-container fluid id="liveQuiz" class="pa-0">
    <v-row justify="center">
      <iframe
        src="https://www.youtube.com/embed/live_stream?channel=UCe6ZvPlyKSvji8xydgxzgTw"
        frameborder="0"
        allowfullscreen
        style="width: 100vw; height: 47vh; z-index: 2"
      ></iframe>

      <iframe
        src="https://app.sli.do/event/nvpm5hhb"
        style="width: 100vw; height: 48vh; z-index: 1"
        allowfullscreen
        frameborder="0"
        class="mt-n3"
      ></iframe>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LiveQuiz",
  metaInfo: {
    title: "Interactive Quiz",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "One of its kind interactive quiz format where everyone can participate virtually from anywhere. Plusminus conducts regular interactive Quiz to improve the concept building and understanding for all the subjects free for all on plusminus youtube channel.",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "DSSSB Classes. DSSSB Online Classes, DSSSB Quiz, DSSSB LDC online classes, DSSSB , Online Quiz , Live Quiz , Classes , Online Classes.",
      },
      {
        vmid: "robots",
        name: "robots",
        content: "index, nofollow",
      },
    ],
  },
};
</script>